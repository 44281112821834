"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dispatchCustomerIdChangeEvent = exports.customerIdChangeEvent = exports.dispatchCustomerShippingAddressChangedEvent = exports.setWSConnectionStatus = exports.dispatchWSConnectionEvent = exports.requestFastenersExistenceEvent = exports.measurementUnitChangedEvent = exports.hideModal = exports.dispatchHideModalEvent = exports.dispatchSetValueModal = exports.dispatchSetValueModalEvent = exports.showModal = exports.dispatchShowModalEvent = exports.selectedObjectsChangedEvent = exports.takeIsometricScreenshotEvent = exports.responseScreenshotsEvent = exports.requestScreenshotsEvent = exports.updateFastenersEvent = exports.deleteFastenersEvent = exports.addFastenersEvent = exports.selectFastenerEvent = exports.resetTransformationEvent = exports.showNotification = exports.dispatchNotificationEvent = exports.updateServicesToBOMEvent = exports.removeServicesFromBOMEvent = exports.addServicesToBOMEvent = exports.updateItemsToBOMEvent = exports.removeItemsFromBOMEvent = exports.addItemsToBOMEvent = exports.refreshSceneEvent = exports.createNewProjectEvent = exports.addCatalogItemEvent = void 0;
const events_1 = require("~/consts/events");
const addCatalogItemEvent = (item) => {
    return new CustomEvent(events_1.addCatalogItem, {
        detail: item,
    });
};
exports.addCatalogItemEvent = addCatalogItemEvent;
exports.createNewProjectEvent = new CustomEvent(events_1.createNewProject, {
    detail: {},
});
exports.refreshSceneEvent = new CustomEvent(events_1.refreshScene);
const addItemsToBOMEvent = (data) => new CustomEvent(events_1.addItemsToBOM, {
    detail: data,
});
exports.addItemsToBOMEvent = addItemsToBOMEvent;
const removeItemsFromBOMEvent = (data) => new CustomEvent(events_1.removeItemsFromBOM, {
    detail: data,
});
exports.removeItemsFromBOMEvent = removeItemsFromBOMEvent;
const updateItemsToBOMEvent = (data) => new CustomEvent(events_1.updateItemsToBOM, {
    detail: data,
});
exports.updateItemsToBOMEvent = updateItemsToBOMEvent;
const addServicesToBOMEvent = (data) => new CustomEvent(events_1.addServicesToBOM, {
    detail: data,
});
exports.addServicesToBOMEvent = addServicesToBOMEvent;
const removeServicesFromBOMEvent = (data) => new CustomEvent(events_1.removeServicesFromBOM, {
    detail: data,
});
exports.removeServicesFromBOMEvent = removeServicesFromBOMEvent;
const updateServicesToBOMEvent = (data) => new CustomEvent(events_1.updateServicesToBOM, {
    detail: data,
});
exports.updateServicesToBOMEvent = updateServicesToBOMEvent;
const dispatchNotificationEvent = (data) => {
    return new CustomEvent(events_1.dispatchNotification, {
        detail: data,
    });
};
exports.dispatchNotificationEvent = dispatchNotificationEvent;
const showNotification = (data) => {
    document.body.dispatchEvent((0, exports.dispatchNotificationEvent)(data));
};
exports.showNotification = showNotification;
exports.resetTransformationEvent = new CustomEvent(events_1.resetTransformation, {
    detail: {},
});
const selectFastenerEvent = (data) => new CustomEvent(events_1.selectFastener, {
    detail: data,
});
exports.selectFastenerEvent = selectFastenerEvent;
const addFastenersEvent = (data) => {
    return new CustomEvent(events_1.addFasteners, {
        detail: data,
    });
};
exports.addFastenersEvent = addFastenersEvent;
const deleteFastenersEvent = (data) => {
    return new CustomEvent(events_1.deleteFasteners, {
        detail: data,
    });
};
exports.deleteFastenersEvent = deleteFastenersEvent;
const updateFastenersEvent = (data) => {
    return new CustomEvent(events_1.updateFasteners, {
        detail: data,
    });
};
exports.updateFastenersEvent = updateFastenersEvent;
const requestScreenshotsEvent = (explosionCoefficient) => {
    return new CustomEvent(events_1.requestScreenshots, {
        detail: explosionCoefficient,
    });
};
exports.requestScreenshotsEvent = requestScreenshotsEvent;
const responseScreenshotsEvent = (data) => {
    return new CustomEvent(events_1.responseScreenshots, {
        detail: data,
    });
};
exports.responseScreenshotsEvent = responseScreenshotsEvent;
const takeIsometricScreenshotEvent = (data) => {
    return new CustomEvent(events_1.takeIsometricScreenshot, {
        detail: data,
    });
};
exports.takeIsometricScreenshotEvent = takeIsometricScreenshotEvent;
const selectedObjectsChangedEvent = (uuids) => new CustomEvent(events_1.selectedObjectsChanged, {
    detail: uuids,
});
exports.selectedObjectsChangedEvent = selectedObjectsChangedEvent;
const dispatchShowModalEvent = (data) => {
    return new CustomEvent(events_1.dispatchShowModal, {
        detail: data,
    });
};
exports.dispatchShowModalEvent = dispatchShowModalEvent;
const showModal = (data) => {
    document.body.dispatchEvent((0, exports.dispatchShowModalEvent)(data));
};
exports.showModal = showModal;
const dispatchSetValueModalEvent = (value) => {
    return new CustomEvent(events_1.valueModalChanged, {
        detail: value,
    });
};
exports.dispatchSetValueModalEvent = dispatchSetValueModalEvent;
const dispatchSetValueModal = (value) => {
    document.body.dispatchEvent((0, exports.dispatchSetValueModalEvent)(value));
};
exports.dispatchSetValueModal = dispatchSetValueModal;
const dispatchHideModalEvent = () => {
    return new CustomEvent(events_1.dispatchHideModal);
};
exports.dispatchHideModalEvent = dispatchHideModalEvent;
const hideModal = () => {
    document.body.dispatchEvent((0, exports.dispatchHideModalEvent)());
};
exports.hideModal = hideModal;
const measurementUnitChangedEvent = (isImperial) => new CustomEvent(events_1.measurementUnitChanged, {
    detail: isImperial,
});
exports.measurementUnitChangedEvent = measurementUnitChangedEvent;
const requestFastenersExistenceEvent = (data) => new CustomEvent(events_1.requestFastenersExistence, {
    detail: data,
});
exports.requestFastenersExistenceEvent = requestFastenersExistenceEvent;
const dispatchWSConnectionEvent = (connectionStatus) => {
    return new CustomEvent(events_1.wsConnectionStatusChanged, {
        detail: {
            status: connectionStatus,
        },
    });
};
exports.dispatchWSConnectionEvent = dispatchWSConnectionEvent;
const setWSConnectionStatus = (connectionStatus) => {
    document.body.dispatchEvent((0, exports.dispatchWSConnectionEvent)(connectionStatus));
};
exports.setWSConnectionStatus = setWSConnectionStatus;
const dispatchCustomerShippingAddressChangedEvent = ({ addressId, userId, }) => {
    return new CustomEvent(events_1.customerShippingAddressChanged, {
        detail: {
            addressId,
            userId,
        },
    });
};
exports.dispatchCustomerShippingAddressChangedEvent = dispatchCustomerShippingAddressChangedEvent;
const customerIdChangeEvent = ({ customer, distributorId }) => {
    return new CustomEvent(events_1.customerIdChanged, {
        detail: {
            customer,
            distributorId,
        },
    });
};
exports.customerIdChangeEvent = customerIdChangeEvent;
const dispatchCustomerIdChangeEvent = ({ customer, distributorId, }) => {
    document.body.dispatchEvent((0, exports.customerIdChangeEvent)({ customer, distributorId }));
};
exports.dispatchCustomerIdChangeEvent = dispatchCustomerIdChangeEvent;
