"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXTRUSION_END = exports.ITEM_TYPE = exports.TRANSFORM_MODE = exports.unitVec = exports.WORLD_AXES = exports.SCALE_AXES = exports.Axis = void 0;
const three_1 = require("three");
const directions = {
    'X': new three_1.Vector3(1, 0, 0),
    '-X': new three_1.Vector3(-1, 0, 0),
    'Y': new three_1.Vector3(0, 1, 0),
    '-Y': new three_1.Vector3(0, -1, 0),
    'Z': new three_1.Vector3(0, 0, 1),
    '-Z': new three_1.Vector3(0, 0, -1),
};
const axes = {
    positiveX: 'X',
    negativeX: '-X',
    positiveY: 'Y',
    negativeY: '-Y',
    positiveZ: 'Z',
    negativeZ: '-Z',
};
const positiveAxes = {
    positiveX: 'X',
    positiveY: 'Y',
    positiveZ: 'Z',
};
const INCH_TO_MM = 25.4;
const MM_TO_INCH = 1 / INCH_TO_MM;
const M_TO_FT = 3.281;
const FT_TO_M = 1 / M_TO_FT;
const SQ_M_TO_SQ_FT = 10.764;
const SQ_FT_TO_SQ_M = 1 / SQ_M_TO_SQ_FT;
const LBS_TO_KG = 0.45359;
// eslint-disable-next-line no-shadow
var Axis;
(function (Axis) {
    Axis["x"] = "x";
    Axis["y"] = "y";
    Axis["z"] = "z";
})(Axis = exports.Axis || (exports.Axis = {}));
// eslint-disable-next-line no-shadow
var SCALE_AXES;
(function (SCALE_AXES) {
    SCALE_AXES["XY"] = "XY";
    SCALE_AXES["X"] = "X";
    SCALE_AXES["Z"] = "-Z";
})(SCALE_AXES = exports.SCALE_AXES || (exports.SCALE_AXES = {}));
exports.WORLD_AXES = {
    X: new three_1.Vector3(1, 0, 0),
    Y: new three_1.Vector3(0, 1, 0),
    Z: new three_1.Vector3(0, 0, 1),
};
exports.unitVec = new three_1.Vector3(1, 1, 1);
// eslint-disable-next-line no-shadow
var TRANSFORM_MODE;
(function (TRANSFORM_MODE) {
    TRANSFORM_MODE["TRANSLATE"] = "translate";
    TRANSFORM_MODE["ROTATE"] = "rotate";
    TRANSFORM_MODE["SCALE"] = "scale";
    TRANSFORM_MODE["NONE"] = "none";
})(TRANSFORM_MODE = exports.TRANSFORM_MODE || (exports.TRANSFORM_MODE = {}));
// eslint-disable-next-line no-shadow
var ITEM_TYPE;
(function (ITEM_TYPE) {
    ITEM_TYPE["EXTRUSION"] = "Extrusion";
    ITEM_TYPE["COMPONENT"] = "Component";
    ITEM_TYPE["PANEL"] = "Panel";
})(ITEM_TYPE = exports.ITEM_TYPE || (exports.ITEM_TYPE = {}));
const TERRACOTTA_COLOR_VECTOR = new three_1.Vector3(0xe1 / 255, 0x5e / 255, 0x37 / 255);
const TERRACOTTA_COLOR = 0xe15e37;
const COLLISION_PRECISION = 1e-2;
const DOUBLE_COLLISION_PRECISION = 2 * COLLISION_PRECISION;
exports.EXTRUSION_END = 'Extrusion end';
exports.default = {
    BUTTONS: {
        LEFT: 0,
        MIDDLE: 1,
        RIGHT: 2,
    },
    CANVAS_ID: 'canvasViewer',
    VIEWCUBE_ID: 'cube',
    FPS_COUNTER_ID: 'fps-counter',
    OBJECT_TEST: 'object|test',
    EMPTY_MODEL: 'empty model',
    COLLISION_BOX_PREFIX: 'X|',
    DEFAULT_CAMERA_POSITION: { x: 80, y: 80, z: -60 },
    DEFAULT_CAMERA_FOV: 120,
    DEFAULT_CAMERA_NEAR: 0.1,
    DEFAULT_CAMERA_FAR: 5000,
    DEFAULT_CAMERA_ZOOM: 10,
    DEFAULT_CAMERA_MIN_ZOOM: 0.01,
    DEFAULT_CAMERA_MAX_ZOOM: 100,
    DEFAULT_CAMERA_ZOOM_STEP: 0.5,
    DEFAULT_ZOOM_TO_POINT_DISTANCE: 100,
    DEFAULT_GRID_SIZE: 240,
    DEFAULT_GRID_CELL_SIZE: 12,
    DEFAULT_GRID_DIVISIONS_PER_CELL: 48,
    DEFAULT_GRID_SIZE_SI: 6000 * MM_TO_INCH,
    DEFAULT_GRID_CELL_SIZE_SI: 250 * MM_TO_INCH,
    DEFAULT_GRID_DIVISIONS_PER_CELL_SI: 50,
    DEFAULT_GRID_MAJOR_COLOR: 0x555555,
    // DEFAULT_GRID_MAJOR_COLOR: 0xdadada,
    DEFAULT_GRID_MINOR_COLOR: 0xa1a1a1,
    DEFAULT_SUBGRID_COLOR: 0xdadada,
    DEFAULT_GRID_BACKGROUND_COLOR: 0xa6b6d2,
    DEFAULT_METAL_BASE_MATERIAL_COLOR: 0x999999,
    DEFAULT_DARK_METAL_BASE_MATERIAL_COLOR: 0x555555,
    DEFAULT_PANEL_CUBE_SIZE: 0.2,
    PANEL_CUBE_COLOR: 0x0632ac,
    HIGHLIGHTED_PANEL_CUBE_COLOR: 0xe15e37,
    DELTA_X_LEADER_COLOR: 0xe51849,
    DELTA_Y_LEADER_COLOR: 0x2bb985,
    DELTA_Z_LEADER_COLOR: 0x1157d7,
    DELTA_X_LABEL_PREFIX: 'ΔX',
    DELTA_Y_LABEL_PREFIX: 'ΔY',
    DELTA_Z_LABEL_PREFIX: 'ΔZ',
    DISTANCE_LABEL_PREFIX: 'Δ',
    POINT_CUBE_COLOR: 0xe15e37,
    POINT_CUBE_OPACITY: 0.75,
    POINT_CUBE_SIZE: 0.04,
    POINT_CUBE_VERTEX_SNAP_RADIUS: 0.25,
    MEASUREMENT_GRID_OPACITY: 0.25,
    ROTATION_ANGLE_ADJUSTMENT_COEFFICIENT: 10000,
    CAMERA_OVER_POINT_ROTATION_SPEED: 100,
    LIGHT_COLOR: 0xffffff,
    CLEAR_COLOR: 0xffffff,
    AABB_PREFIX: 'AABB|',
    OBB_PREFIX: 'OBB|',
    OUTER_BOX_PREFIX: 'OB|',
    PANEL_CUBE_PREFIX: 'PanelCube|',
    OUTER_BOX_PADDING: 0,
    COLLISION_BOX_PADDING: 0.05,
    SELECTION_BOX_PADDING: 0,
    SELECTION_BOX_COLOR: 0xffff00,
    SELECTION_BOX_COLOR_VECTOR: new three_1.Vector3(1, 1, 0),
    ACTIVE_SELECTION_BOX_COLOR: TERRACOTTA_COLOR,
    ACTIVE_SELECTION_BOX_COLOR_VECTOR: TERRACOTTA_COLOR_VECTOR,
    OUTER_BOX_COLOR: 0x0661ac,
    OUTER_BOX_OPACITY: 0.25,
    COLLISION_BOX_COLOR: 0xf44336,
    COLLISION_BOX_COLOR_VECTOR: new three_1.Vector3(0xf4 / 255, 0x43 / 255, 0x36 / 255),
    WHITE_COLOR: 0xffffff,
    RED_COLOR: 0xff0000,
    GREEN_COLOR: 0x00ff00,
    TERRACOTTA_COLOR,
    TERRACOTTA_COLOR_VECTOR,
    SLOT_CENTER_FOUND_COLOR: 0x50fffd,
    DEFAULT_CONNECTION_SPHERE_RADIUS: 0.5,
    INCREASED_CONNECTION_SPHERE_RADIUS: 0.75,
    PANEL_INTERSECTING_AREA_THRESHOLD_LENGTH: 8,
    PANEL_FASTENER_EDGE_DISTANCE: 2,
    PANEL_FASTENERS_MAX_DISTANCE: 18,
    DRAW_MODE_OPACITY: 1.0,
    FASTENER_MODE_OPACITY: 0.3,
    DEFAULT_EXTRUDE_STEP: 0.25,
    DEFAULT_EXTRUDE_STEP_SI: 5 * MM_TO_INCH,
    DEFAULT_EXTRUDE_STEP_MM: 5,
    MINIMUM_SCALE_LIMIT_Z: 0,
    INSERTED_OBJECT_ROTATION_THRESHOLD: 1,
    CLOSEST_NEIGHBOR_DISTANCE_THRESHOLD: 2.0,
    CLOSEST_PERPENDICULAR_SLOT_THRESHOLD: 0.5,
    DEFAULT_SCALING_DIRECTION: '-Z',
    DIRECTIONS_LIST: directions,
    DP_UP_DIR: directions['-Z'],
    DP_WIDTH_COMPONENT_INDEX: 0,
    DP_HEIGHT_COMPONENT_INDEX: 1,
    DP_LENGTH_COMPONENT_INDEX: 2,
    AXES: axes,
    POSITIVE_AXES: positiveAxes,
    SNAP_TO_THE_EDGE_THRESHOLD: 0.5,
    SNAP_TO_THE_CENTER_THRESHOLD: 0.5,
    SNAP_BY_BBOX_THRESHOLD: 0.5,
    SNAP_BY_PLANE_THRESHOLD: 0.5,
    SNAP_BY_PLANE_HIGHLIGHT_INDENT: 0.5,
    SNAP_HIGHLIGHT_INDENT: 0.25,
    DEFAULT_MAX_STOCK_LENGTH: 500,
    DEFAULT_MAX_STOCK_WIDTH: 500,
    DEFAULT_MIN_LENGTH: 1,
    DEFAULT_MIN_WIDTH: 1,
    ANGLED_EXTRUSION_LIMIT_LOWER: Math.PI / 3,
    ANGLED_EXTRUSION_LIMIT_UPPER: Math.PI / 6,
    HALF_PI: Math.PI / 2,
    FLOAT_EPSILON: 1e-8,
    TWO_FLOAT_EPSILON: 1e-2,
    THREE_FLOAT_EPSILON: 1e-3,
    STANDARD_PRECISION: 1e-5,
    MIN_DISTANCE_FROM_CAMERA_TO_TARGET: 0.7,
    MAX_DISTANCE_FROM_CAMERA_TO_TARGET: 160,
    ZOOM_TO_FIT_ADDITIONAL_SIZE_COEF: 1.25,
    SCREENSHOT_SIDE_LENGTH_PX: 1096,
    ISOMETRIC_SCREENSHOT_SIDE_LENGTH_PX: 300,
    SCREENSHOT_CAMERA_DIRECTION: new three_1.Vector3(1, 1, -1),
    LEADER_COLOR: 0x404040,
    DASH_LENGTH: 0.5,
    GAP_LENGTH: 0.5,
    ARROW_HEAD_MIN_DISPLAY_DISTANCE: 2,
    ARROW_HEAD_LENGTH: 1,
    ARROW_HEAD_WIDTH: 0.5,
    BOM_BALLOON_ARROW_HEAD_LENGTH: 0.5,
    BOM_BALLOON_ARROW_HEAD_WIDTH: 0.25,
    INCH_TO_MM,
    MM_TO_INCH,
    M_TO_FT,
    FT_TO_M,
    SQ_M_TO_SQ_FT,
    SQ_FT_TO_SQ_M,
    LBS_TO_KG,
    COLLISION_PRECISION_DURING_SNAPPING: 0.01,
    WIRE_MESH_PANEL_INITIAL_SIZE: 4,
    PLASTIC_PANEL_INITIAL_SIZE: 1,
    MAX_SLOT_DETECTION_DISTANCE: 0.05,
    SLOT_HIGHLIGHT_OFFSET: 0.005,
    BUILD_PLANE_RENDER_ORDER: 5,
    PRECISION_FOR_MASTER_SNAPPING_POINT_DETECTION: 0.01,
    PRECISION_FOR_SYMMETRY_CHECK: 0.01,
    SQUARE_SIDE_FOR_SNAPPING_HIGHLIGHT_FOR_COMPONENTS: 1.5,
    DEFAULT_SCALE_FOR_EXTRUSIONS: 1,
    MAX_DISTANCE_TO_INTERSECTION: 10000,
    DISTANCE_TO_DETECT_NEARBY_OBJECTS: 1,
    ANGLE_STEP_FOR_SLOTS_ALIGNMENT: Math.PI / 2,
    END_FACE_CORNERS_OFFSET: 0.001,
    END_FACE_CORNERS_COUNT: 4,
    PRECISION_DURING_SLOT_NORMALS_COOPERATION: 1e-3,
    ADDITIONAL_BBOX_OFFSET_FOR_SNAPPING_POSITION_COMPUTION: 0.05,
    TAKE_SCREENSHOT_TIMEOUT: 15000,
    PRIOR_AXES_FOR_SIDE_FOR_0_DEGREES_CENTRAL_CONNECTOR_WHILE_CONNECTED_EXTRUSION_PARALLEL_TO_Z: [
        new three_1.Vector3(1, 0, 0),
        new three_1.Vector3(0, 1, 0),
        new three_1.Vector3(-1, 0, 0),
        new three_1.Vector3(0, -1, 0),
    ],
    PRIOR_AXES_FOR_SIDE_FOR_0_DEGREES_CENTRAL_CONNECTOR_WHILE_CONNECTED_EXTRUSION_PERPENDICULAR_TO_Z: [
        new three_1.Vector3(0, 0, 1),
        new three_1.Vector3(1, 0, 0),
        new three_1.Vector3(0, 1, 0),
        new three_1.Vector3(0, 0, -1),
        new three_1.Vector3(-1, 0, 0),
        new three_1.Vector3(0, -1, 0),
    ],
    DEFAULT_SLOT_THICKNESS: 0.1,
    COLOR_LERP_OPACITY: 0.5,
    ZOOM_FOR_ZOOM_BUTTONS: 0.0625,
    COLLISION_PRECISION,
    DOUBLE_COLLISION_PRECISION,
    SCREENSHOT_EXPLODED_VIEW_PANEL_OPACITY: 0.5,
    SCREENSHOT_EXPLODED_VIEW_OFFSET: 1.5,
};
